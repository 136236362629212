<template>
  <v-app id="inspire">
    <v-main>
      <v-container
      v-if="hashVerified === true"
        fluid
        fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-3">
              <v-toolbar color="orange lighten-2">
                <v-toolbar-title>Riggott IMS - Reset Password</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                    prepend-icon="mdi-account"
                    name="password"
                    label="Password"
                    type="password"
                    v-model="password"
                  ></v-text-field>
                  <v-text-field
                    prepend-icon="mdi-account"
                    name="password"
                    label="Repeat Password"
                    type="password"
                    v-model="verifyPassword"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="orange lighten-2"
                  @click="resetPassword">
                  Submit
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
      <v-snackbar
        v-model="showMessage"
          :timeout="5000"
      >
        {{ message }}
        <template v-slot:action="{ attrs }">
        <v-btn
          class="text-h5"
          color="grey darken-2"
          text
          v-bind="attrs"
          @click="showMessage = false"
        >
          Close
        </v-btn>
      </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'ResetPassword',
  data() {
    return {
      password: '',
      verifyPassword: '',
      showMessage: false,
      message: '',
      hashVerified: false,
    };
  },
  methods: {
    checkHash() {
      const postData = {};
      postData.hash = this.$route.params.hash;
      postData.userid = this.$route.params.userid;
      axios.post('/users/checkResetHash.json', postData)
        .then((response) => {
          this.showMessage = true;
          this.message = response.data.message;
          this.hashVerified = response.data.verified;
        });
    },
    resetPassword() {
      const postData = {};
      if(this.password === this.verifyPassword) {
        postData.password = this.password;
        postData.userid = this.$route.params.userid;
        axios.post('/users/resetPassword.json', postData)
        .then((response) => {
          this.showMessage = true;
          this.message = response.data.message;
        });
      } else {
        this.showMessage = true;
        this.message = 'Sorry, those passwords do not match. Please re-enter.';
      }
    }
  },
  mounted() {
    this.checkHash();
  },
};
</script>
